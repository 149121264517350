import restaurant from "./images/restaurant.jpg";
import logou from "./images/logo.png";
import img_card from "./images/1.jpg";
import imgOmSuflet from "./images/2.jpg";
import imgheader from "./images/2.jpg";
import imgheadermb from "./images/2.jpg";
import imgheadermiini from "./images/7.jpg";
import imgheadermiinimb from "./images/7.jpg";

{/*cooperativaagrostoc@gmail.com */}

const data = {
    introData: [{
        mire: "C.Î. AGROSTOC",
        mireasa: "",
        data: "9 Iunie 2022",
        logo: logou,
        data_confirmare: "2 iunie 2022",
        savedata: "Invitație la Adunarea Generală a Membrilor",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "cooperativaagrostoc@gmail.com", 
       tel: "+373 69 079 039",
       phone: "tel:+37369079039",
       viber: "viber://chat?number=%2B37369079039",
       whatsapp: "https://wa.me/+37369079039",
       messenger: "https://www.messenger.com/t/CiAgrostoc",
       tel1: "+373 68 274 777",
       phone1: "tel:+37368274777",
       viber1: "viber://chat?number=%2B37368274777",
       whatsapp1: "https://wa.me/+37368274777",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentelor",
            name: "Grand Elysée",
            data: "9 iunie 2022, joi",
            ora: "Adunarea Generală - ora 09:30",
            ora1: "Partea Festivă - ora 17:30",
            adress: "Strada Chișinăului 45, Sîngera, Moldova",
            harta: "https://g.page/grand_elysee?share",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10899.29043056449!2d29.0053241!3d46.9258694!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8d6e300c210a912e!2sGrand%20Elys%C3%A9e!5e0!3m2!1sro!2s!4v1651689667251!5m2!1sro!2s",
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "20 ani împreună!",
            message: "ADUNAREA GENERALĂ A MEMBRILOR",
            message1: "C.Î. ”AGROSTOC„",
            parintii_nume1: "Vasile & Diana",
            parintii_nume2: "Ghenadie & Oxana",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Vasile & Svetlana",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "",
            message: "\"Împreună suntem mai puternici!\"",
        }
    ],

}

export default data;