import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";




import CardLocal from "../cardLocal";


import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import CardInvitatie from "./CardInvitatie";
import CardInvitatieM from "./CardInvitatieM";
import logouMembru from "../images/8.png"


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".5" >
                        <div id="header" className="row text-center align-items-center mt-5" style={{ position: 'absolute' }}>
                            <div className="col-12 ">
                            <h4 style={{ fontSize: '30px' }}>{item.savedata}</h4>
                                <h1 style={{ fontSize: '50px', textShadow: `1px 1px 2px black, 0 0 .5em grey, 0 0 1em grey` }}>{item.mire}  {item.mireasa}</h1>
                                <h4 style={{ fontSize: '40px' }}>{item.data}</h4>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



<CardInvitatieM/>  


      {/*     {data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={item.img} height="400px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '500px', padding: '20px' }}>
                                <h4 style={{ color: 'gold' }}>{item.message}</h4>
                                <h4 style={{ color: 'gold' }}>{item.message1}</h4>
                                <br/>
                                <h1 style={{ fontSize: '35px' }}>{item.title}</h1>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}
*/}  
  

       {/*<CardInvitatie/>  */}

          

       {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mt-5 pt-5" style={{ maxWidth: '450px', padding: '20px'  }}>
                                <h1 style={{ fontSize: '35px', color: 'white' }}>{item.title}</h1>
                                <h4 style={{ fontSize: '35px', color: 'white' }}>{item.message}</h4>
                                <img id="imgm"  src={logouMembru}/>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}
         


            <CardLocal />




            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.mire}  {item.mireasa}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;