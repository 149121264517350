import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { blueGrey, green, red } from "@mui/material/colors";
import data from "../Data"


const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true)
    const [minLengthError, setMinLengthError] = useState(false)
    const [maxLengthError, setMaxLengthError] = useState(false)
    const [telError, setTelError] = useState(true)
    const [nameError, setNameError] = useState(true)
    const [inputValidName, setInputValidName] = useState(false)
    const [inputValidTel, setInputValidTel] = useState(false)
    const [inputValidPartener, setInputValidPartener] = useState(false)


    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'minLength':
                    value.length < validations[validation] ? setMinLengthError(true) : setMinLengthError(false)
                    break;
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true)
                    break;
                case 'maxLength':
                    value.length > validations[validation] ? setMaxLengthError(true) : setMaxLengthError(false)
                    break;
                case 'telError':
                    const check = /^\+[\d\(\)\ -]{8,20}\d$/.test(value);
                    check ? setTelError(false) : setTelError(true)
                    break;
                case 'nameError':
                    const ername = /^[A-Za-z\s]*\s[A-Za-z\s]*$/.test(value);
                    ername ? setNameError(false) : setNameError(true)
                    break;


            }

        }
    }, [value])

    useEffect(() => {
        if (isEmpty || minLengthError || nameError) {
            setInputValidName(false)
        } else {
            setInputValidName(true)
        }
    }, [isEmpty, minLengthError, nameError])


    useEffect(() => {
        if (isEmpty || minLengthError || maxLengthError || telError) {
            setInputValidTel(false)
        } else {
            setInputValidTel(true)
        }
    }, [isEmpty, minLengthError, maxLengthError, telError])


    useEffect(() => {
        if (isEmpty || minLengthError) {
            setInputValidPartener(false)
        } else {
            setInputValidPartener(true)
        }
    }, [isEmpty, minLengthError])

    return {
        isEmpty,
        minLengthError,
        maxLengthError,
        telError,
        nameError,
        inputValidName,
        inputValidTel,
        inputValidPartener,
    }


};

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations)

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onBlur = (e) => {
        setDirty(true)
    }


    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid

    }

}


const FormValidation = (props) => {
    const partener = useInput('', { isEmpty: true, minLength: 3 });
    const name = useInput('', { isEmpty: true, minLength: 3, nameError: true });
    const phone = useInput('', { isEmpty: true, minLength: 9, maxLength: 20, telError: true })
    const prezenta = useInput('Da, vom fi prezenti')
    const ora = useInput('')
    const maturi = useInput('')
    const copii = useInput('')
    const message = useInput('')

    const [success, setSuccess] = useState(false);

    const onSubmit = () => {
        Swal.fire("Expediat cu SUCCES!", "Vă mulțumim pentru confirmare!", "success");
        setSuccess(true);
        
        setTimeout(function(){
            window.location.reload(1);
         }, 4000); 
    }


    return (
        <>

            <form className="forms container text-center">

                {data.introData.map((item) => {
                    return (

                        <div>
                            <input type="hidden" name="project_name" value="Invitatie Membru" />
                            <input type="hidden" name="admin_email" value={item.email} />
                            <input type="hidden" name="form_subject" value="Confirmare Invitatii Membru" />
                        </div>


                    )
                })}






                <div className="row justify-content-center text-center">
                    <div className="col-md-4 m-2 p-2 ">
                        <label className="form-label">
                            * Partener / Membru:
                        </label>
                        <input className="form-control"
                            onChange={e => partener.onChange(e)}
                            onBlur={e => partener.onBlur(e)}
                            name="Partener"
                            value={partener.value}
                            type="text"
                            placeholder="ex. Nufarm SRL"
                        />
                        {(partener.isDirty && partener.isEmpty) && <div style={{ color: 'red' }}>Introduceți denumirea companiei sau a membrului!</div>}
                        {(partener.isDirty && partener.minLengthError) && <div style={{ color: 'red' }}>Denumirea companiei sau a mebrului nu poate conține mai puțin de trei caractere!</div>}

                    </div>
                    <div className="col-md-4 m-2 p-2 ">
                        <label className="form-label">
                            * Numele și Prenumele:
                        </label>
                        <input className="form-control"
                            onChange={e => name.onChange(e)}
                            onBlur={e => name.onBlur(e)}
                            name="numele partenerului"
                            value={name.value}
                            type="text"
                            placeholder="ex. Rotaru Mihail"
                        />
                        {(name.isDirty && name.isEmpty) && <div style={{ color: 'red' }}>Introduceți numele și prenumele dvs!</div>}
                        {(name.isDirty && name.minLengthError) && <div style={{ color: 'red' }}>Numele nu poate conține mai puțin de trei caractere!</div>}
                        {(name.isDirty && name.nameError) && <div style={{ color: 'red' }}>Numele și Prenumele nu trebuie să conțină: simboluri (@,$..), diacritice (ă,î,â,ș,ț) sau cifre!</div>}
                    </div>
                    <div className="col-md-4 m-2 p-2">
                        <label className="form-label"> * Număr de contact:</label>
                        <input className="form-control"
                            name="nr. de contact"
                            onChange={e => phone.onChange(e)}
                            onBlur={e => phone.onBlur(e)}
                            value={phone.value}
                            type="tel"
                            placeholder="ex. +373 111 22 333"
                        />
                        {(phone.isDirty && phone.isEmpty) && <div style={{ color: 'red' }}>Introduceți numărul dvs de contact!</div>}
                        {(phone.isDirty && phone.telError) && <div style={{ color: 'red' }}>Introduceți numărul de contact în formatul +(codul țării) XXX XX XXX, la fe numarul nu poate conține mai puțin de 8 cifre!</div>}
                    </div>
                </div>

                <hr />



                <div className="row justify-content-center text-center">
                    <label className="form-check-label"> * Selectați Prezența:</label>

                    <div className="col-md-4 col-lg-4 col-8 form-yes mx-2">

                        <div className="row row-yes">
                            <div className="col-12">
                                <input className="form-check-input"
                                    type="radio"
                                    name="prezenta"
                                    value="Da, vom fi prezenti"
                                    checked={prezenta.value === "Da, vom fi prezenti"}
                                    onChange={e => prezenta.onChange(e)}
                                    onBlur={e => prezenta.onBlur(e)}
                                />
                                <label className="" style={{ color: green[500], fontSize: '18px' }}> Da vom fi prezenți</label>
                            </div>
<div className="col-12">
<input className="form-check-input"
                                        type="checkbox"
                                        name="prezenta ora"
                                        value="ora 9:30"
                                        checked={ora.value === "ora 9:30"}
                                        onChange={e => ora.onChange(e)}
                                        onBlur={e => ora.onBlur(e)}
                                    />
                                    <label className="" style={{ color: blueGrey[500], fontSize: '17px' }}>ora 09:30</label>
                            
</div>
                                    <div className="col-12">
<input className="form-check-input"
                                        type="checkbox"
                                        name="prezenta ora"
                                        value="ora 17:30"
                                        checked={ora.value === "ora 17:30"}
                                        onChange={e => ora.onChange(e)}
                                        onBlur={e => ora.onBlur(e)}
                                    />
                                    <label className="" style={{ color: blueGrey[500], fontSize: '17px' }}>ora 17:30</label>
                                
                                    </div>
                                
                               <div className="col-12">
                                  <input className="form-check-input"
                                        type="checkbox"
                                        name="prezenta ora"
                                        value="ora 09:30 si 17:30"
                                        checked={ora.value === "ora 09:30 si 17:30"}
                                        onChange={e => ora.onChange(e)}
                                        onBlur={e => ora.onBlur(e)}
                                    />
                                    <label className="" style={{ color: blueGrey[500], fontSize: '17px' }}>ambele ore</label>
                            
                                   </div>     
                                    


                            <div className="col m-2 p-2">
                                <label className="form-label px-2">
                                    Numărul de persoane prezente:</label>
                                <input id="nr persoane prezente"
                                    className="form-control"
                                    type="number"
                                    placeholder="ex. 2"
                                    name="nr persoane prezente"
                                    min="0" max="10"
                                    value={maturi.value}
                                    onChange={e => maturi.onChange(e)}
                                    onBlur={e => maturi.onBlur(e)}
                                />

                            </div>


                        </div>


                    </div>

                    <div className="col-md-4 col-lg-4 col-8  form-no mx-2">

                        <div className="row row-no">
                            <div className="col-12 m-2">
                                <input className="form-check-input"
                                    type="radio"
                                    name="prezenta"
                                    value="Nu vom fi prezenti"
                                    checked={prezenta.value === "Nu vom fi prezenti"}
                                    onChange={e => prezenta.onChange(e)}
                                    onBlur={e => prezenta.onBlur(e)}
                                />
                                <label className="" style={{ color: red[500], fontSize: '18px' }}> Nu vom fi prezenți</label>

                            </div>
                            <div className="col-12 ">
                                <label className="form-label m-2 px-2">
                                    Lăsați un mesaj sau detalii:</label>
                                <textarea
                                    name="mesaj"
                                    value={message.value}
                                    onChange={e => message.onChange(e)}
                                    onBlur={e => copii.onBlur(e)}
                                />

                            </div>

                        </div>

                    </div>


                </div>





                <hr />

                <div>
                    <button disabled={!name.inputValidName || !phone.inputValidTel || !partener.inputValidPartener} onClick={onSubmit} type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-submit  mb-3 p-3">Confirmați Prezența</button>
                </div>



            </form>

        </>
    );

}

export default FormValidation;